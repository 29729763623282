import type {UserCardType, UserPageType} from "~/types/User";
import type {SpecificationType} from "~/types/Specification";

export interface TaskType {
    documentId: string
    id?: number
    name: string
    description: string | null
    executor: UserPageType | null
    owner: UserPageType
    state: TaskStateEnum
    specification_name: string
    specification: SpecificationType,
    execution_location: ExecutionLocationEnum,
    responses: ResponseType[]
    budget: {
        from: number
        to: number
    }
    date: {
        from: string
        to: string
    }
    is_archive: boolean
    compute_allow_response: boolean
    createdAt?: string
    isSavePayment: boolean
}

export interface ResponseType {
    documentId: string
    createdAt: string
    id: string
    is_blocked: boolean
    publishedAt: string
    updatedAt: string
    user: UserPageType
    task: TaskType
}

export enum TaskStateEnum {
    POSTED = 'POSTED',
    SUGGESTED = 'SUGGESTED',
    ACTIVE = 'ACTIVE',
    REVIEW = 'REVIEW',
    COMPLETED = 'COMPLETED',
    CALL_OFF = 'CALL_OFF',
    REFUSED = 'REFUSED',
}

export enum ExecutionLocationEnum {
    DISTANCE = 'DISTANCE',
    AT_CLIENT = 'AT_CLIENT',
    AT_MASTER = 'AT_MASTER',
}

export interface TaskForm {
    owner: string,
    specification: string | null,
    name: string | null,
    description: string | null,
    execution_location: string | null,
    date: {
        from: string | null,
        to: string | null,
    },
    budget: {
        from: string | number | null,
        to: string | number | null,
    },
}

export const TaskFormDefault: TaskForm = {
    owner: '',
    specification: null,
    name: null,
    description: null,
    execution_location: null,
    date: {
        from: null,
        to: null,
    },
    budget: {
        from: null,
        to: null,
    },
}